import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import React from 'react';
import Box from '../Box';

interface LoadingProps extends WithSpacingProps {
  size?: number | string;
  style?: React.CSSProperties;
  isInverted?: boolean;
}

const Loading = withSpacing<LoadingProps>(
  ({ size = '2.4rem', style, isInverted = false, className = '' }) => {
    const borderColor = isInverted
      ? 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) #000 #000'
      : 'rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3) #fff #fff';

    const internalStyle = {
      fontSize: size,
      width: '1em',
      height: '1em',
      borderColor,
    };

    return (
      <Box className={className} style={style} centerContent>
        <div className="loading" style={internalStyle} />
        <style jsx>{`
          .loading {
            border: solid 2px;
            border-width: 0.04em;
            border-width: max(0.04em, 2px);
            border-radius: 50%;
            animation: loading 0.6s infinite linear;
          }

          @keyframes loading {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </Box>
    );
  }
);

export default Loading;
