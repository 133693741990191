import React, { memo, useRef } from 'react';
import dynamic from 'next/dynamic';

import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import useKeyboard from '~/app/lib/hooks/useKeyboard';
import Loading from '../Loading';

const BASE_FONT_SIZE = '1.04rem';

const OrchardSideNavDynamic = dynamic(() => import('./OrchardSideNav'), {
  ssr: false,
});

const SideNav = memo<{
  onCloseClick: () => void;
  onItemClick: () => void;
  isVisible: boolean;
}>(({ isVisible, onCloseClick, onItemClick }) => {
  const rootElRef = useRef<HTMLElement>(null);
  const { userIsLoading, user } = useFetchSessionUser();

  useKeyboard({
    active: isVisible,
    rootElRef,
    restoreFocusOnEscape: true,
    onEscape: onCloseClick,
  });

  // When ssr we always render the 'pending' state until the hydrated on the
  // client and we know if the user is logged in. if they are we continue to
  // show the pending state until the User fetch has resolved and we know which
  // SideNav type to render.
  const isPending = !process.browser || userIsLoading;

  return (
    <aside
      ref={rootElRef}
      data-testid="sideNav"
      style={{
        background: '#000',
        color: '#fff',
        height: '100%',
        boxShadow: '0.1em 0px 1em rgba(0, 0, 0, 0.1)',
        userSelect: 'none',
        verticalAlign: 'middle',
        fontSize: BASE_FONT_SIZE,
        position: 'relative',
      }}
    >
      {isPending && (
        <Loading coverParent style={{ background: '#000' }} zIndex={1} />
      )}
      <div
        style={{
          height: '100%',
          opacity: isPending ? 0.5 : 1,
          pointerEvents: isPending ? 'none' : undefined,
        }}
      >
        {user && (
          <OrchardSideNavDynamic
            isVisible={isVisible}
            onCloseClick={onCloseClick}
            onItemClick={onItemClick}
            user={user}
          />
        )}
      </div>
    </aside>
  );
});

export default SideNav;
